import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Network/UPnP/SuccessBox';
import UPnPTable from 'components/Web_User_Interface/1080p_Series/Network/UPnP/upnpTable';
import DangerBox1 from 'components/Web_User_Interface/1080p_Series/Network/UPnP/DangerBox1';
import DangerBox2 from 'components/Web_User_Interface/1080p_Series/Network/UPnP/DangerBox2';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // UPnP",
  "path": "/Web_User_Interface/1080p_Series/Network/UPnP/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "The UPnP standard is supposed to make your life easy. Every port that is needed publicly by a device on your network will automatically be forwarded. This would make the DDNS Access to your camera very easy...unfortunately it often does not work and might be considered a security hazard by your network administrator.",
  "image": "./WebUI_1080p_SearchThumb_Network_UPnP.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Network_UPnP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Network Menu // UPnP' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='The UPnP standard is supposed to make your life easy. Every port that is needed publicly by a device on your network will automatically be forwarded. This would make the DDNS Access to your camera very easy...unfortunately it often does not work and might be considered a security hazard by your network administrator.' image='/images/Search/WebUI_1080p_SearchThumb_Network_UPnP.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Network_UPnP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Netzwerk/UPnP/' locationFR='/fr/Web_User_Interface/1080p_Series/Network/UPnP/' crumbLabel="UPnP" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/157ef99c738b55ac3edef38add732aac/0931d/1080p_Settings_Network_UPnP.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC1ElEQVQoz4WQW0hUQRjHxwtiZpYrluZ117VtZV1XMZTaWjfT1N1V1ErBDc0tb0WG6zkzZ/fMmZnjJoHXBIPuLxE+JNRDEkGGL70UlC/VS75FN4IiQsPcibNaQS/9+M/D8H0/vm8GiKIIIyBJkgNIYOO9Uwu+8ced6xmb7xyb940/6pt+MjB8XQ4gjInP57NYbdYiGxAhEkQkQqiqqsftamw++vbL2tKH5TeRLH38sfTpx9L7759X+N3ZWcHr9XvbO1zNnjK73WwBgxD5BShCGAqFHBUHnc5qztfCq8vh1ZXw6grna5yHf/5c5Zw/vT9ztb/nQndArm9DNQ5PYTGASBLgxmR3XY2/v4tzHtbgnPO3X8PP3vFvi6/5y1dzt++ccx0f7+meONXvdrYXWcoAQpJfgIIIKVObPLWVZRYJD92aHn04c+3K1NTNqcmR0UuTwxdvqENC58kHsneROp9PtM8oUp+zBCApMlmEjDG3uwEAAKKitsSAwSZ7qd5QW5DZUWFJ35Gm25ZyvG5/sOPQMZtptEm/0Js9d8IMJEkSIYJQkz0eDwAgPi4OgFhHXtLlk8X31Eql2WrIzqqvru7q6zaarLsM5h2pOwszdKhMp/22X4C/5XoAQExMDABgrzFBbMwbaTH5HOWl+jRp4GyHty03IyvfmK9LTk7anFCZEw8ECCOuJrtcrvW1bamgNDsWgOgqY8qLlu1nCpMdDQ1me3meyZBpyNqcGL8pLnpfehSACEGkHUJIa0vL1pTtVbasnsM5ibr0gjw9NhfgfH2Vxbyn9kDT6dbGriPlbrulxGqy7M7U52pv/oMsy4SQECOUEEKpSsiwQgSFQKwoMmaUhs6HCKOUanVGyV8ZIYQxVlWVUkaZhtbEqMqYunFlEYdqiVRBICAFJCkQ0HyMMWWM0P9AImjygAAHRTggQAGiYFCWZRyUsYyxEmG96R9TURSMcTAY/AWHg2wCC4YufAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/157ef99c738b55ac3edef38add732aac/e4706/1080p_Settings_Network_UPnP.avif 230w", "/en/static/157ef99c738b55ac3edef38add732aac/d1af7/1080p_Settings_Network_UPnP.avif 460w", "/en/static/157ef99c738b55ac3edef38add732aac/7f308/1080p_Settings_Network_UPnP.avif 920w", "/en/static/157ef99c738b55ac3edef38add732aac/f056e/1080p_Settings_Network_UPnP.avif 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/157ef99c738b55ac3edef38add732aac/a0b58/1080p_Settings_Network_UPnP.webp 230w", "/en/static/157ef99c738b55ac3edef38add732aac/bc10c/1080p_Settings_Network_UPnP.webp 460w", "/en/static/157ef99c738b55ac3edef38add732aac/966d8/1080p_Settings_Network_UPnP.webp 920w", "/en/static/157ef99c738b55ac3edef38add732aac/082bf/1080p_Settings_Network_UPnP.webp 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/157ef99c738b55ac3edef38add732aac/81c8e/1080p_Settings_Network_UPnP.png 230w", "/en/static/157ef99c738b55ac3edef38add732aac/08a84/1080p_Settings_Network_UPnP.png 460w", "/en/static/157ef99c738b55ac3edef38add732aac/c0255/1080p_Settings_Network_UPnP.png 920w", "/en/static/157ef99c738b55ac3edef38add732aac/0931d/1080p_Settings_Network_UPnP.png 1373w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/157ef99c738b55ac3edef38add732aac/c0255/1080p_Settings_Network_UPnP.png",
              "alt": "Web User Interface - 1080p Series - Network UPnP",
              "title": "Web User Interface - 1080p Series - Network UPnP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <UPnPTable mdxType="UPnPTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox1 mdxType="DangerBox1" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`If you are using Universal Plug & Play for your network devices, please activate the UPnP service to automatically set the `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding`}</a>{` for your camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox2 mdxType="DangerBox2" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      